import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import moment from 'moment';
import formatNumber from "../../../utils/formatNumber";
import {getCollectionWasteReport, getZones} from "../../../api/app";
import {useDispatch, useSelector} from "react-redux";
import cogoToast from "cogo-toast";

let selectedArea = null;
let currentDate = moment().format("YYYY/MM/DD 23:59:59");

const Collection = (props) => {

    document.title = "Collections";

    const token = localStorage.getItem('jwtToken');

    const dispatch = useDispatch();

    const {territories} = useSelector((state) => state.app);
    const [zones, setZones] = useState([]);

    const [loading, setLoading] = useState(true);
    const [areaName, setAreaName] = useState("All Zones");

    useEffect(() => {
        getCollectionWasteReport(currentDate).then((response) => {
            if(!response.error) {
                $('#total-waste').text(formatNumber(response.payload.total_wastes));
                $('#total-paid').text(response.payload.total_collected);
                $('#total-expired').text(response.payload.total_uncollected);
                currentDate = response.payload.current_date;
            }
        });
    }, []);

    useEffect(() => {
        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                selectedArea = response.payload.map((item) => item);
                setLoading(false);
            }
        })
    }, []);

    useEffect(() => {
        let table = $('#td-waste').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Waste Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        fieldname: "type",
                        filter: {
                            "status": "paid",
                            "due_date.str": {$gt: currentDate},
                            "address.location": selectedArea ? { $geoWithin: { $geometry: selectedArea.geometry } } : {}
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: "user product address.property",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.user.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            subscription : `<span class="badge badge-primary tx-white">Subscription</span>`,
                            pickup : `<span class="badge badge-secondary tx-white">Pickup</span>`
                        }[data.product.type];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return capitalize(data.address.meta.suburb);
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return data.status !== "pending" ? data.due_date.str > moment(new Date()).format("YYYY/MM/DD hh:mm") ? `<span class="tx-purple">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="tx-danger">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="badge badge-warning tx-white">Uncollected</span>`;
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            props.history.push(`/user/${extract_td.raw._id}`);
        });

        $('#td-waste tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                capitalize(d.raw.address.property.type) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Date Issued:</td>' +
                '<td>'+moment(d.raw.date.iso).format("Do MMM, YYYY")+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        return () => {
            table.destroy();
        }

    }, []);

    const showDatePicker = () => {
        $("#datepicker").datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            changeMonth: true,
            minDate: new Date(),
            changeYear: true,
            defaultDate: '+1w',
            numberOfMonths: 1,
            onSelect: function(dateText) {
                getCollectionWasteReport(moment(new Date(dateText)).format("YYYY/MM/DD 23:59:59")).then((response) => {
                    if(!response.error) {
                        $('#total-waste').text(formatNumber(response.payload.total_wastes));
                        $('#total-paid').text(response.payload.total_collected);
                        $('#total-expired').text(response.payload.total_uncollected);
                        currentDate = moment(new Date(dateText)).format("YYYY/MM/DD 23:59:59");
                        $('.date-current').text(moment(new Date(dateText)).format("Do MMM, YYYY"));
                        reloadTable();
                    }
                });
            }
        }).datepicker("show")
    }

    const onAreaSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setAreaName(area.name) : setAreaName("All Zones");
        reloadTable();
    }

    const reloadTable = () => {
        $('#td-waste').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Collections</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Collections</h4>
                    </div>
                    <div className="d-none d-md-block d-flex">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i className="wd-10 mg-r-5 fa fa-upload"></i> Export</button>
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={showDatePicker.bind()}><input type="hidden" id="datepicker"/><i className="wd-10 mg-r-5 fa fa-calendar"></i> Date</button>
                        {loading ? <button disabled className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Collecting</button> :
                            <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {areaName}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    <a href="#" onClick={(e) => {e.preventDefault(); onAreaSelected(null)}} className="dropdown-item">All Zones</a>
                                    {zones.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onAreaSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    })}
                                </div>
                            </span> }
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Collections</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-waste" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span className="tx-11 tx-color-02 mg-b-0 mg-t-5 date-current">{currentDate}</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-success tx-semibold mg-b-8">Total Collected</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-paid" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span className="tx-11 tx-color-02 mg-b-0 mg-t-5 date-current">{currentDate}</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger tx-semibold mg-b-8">Total Uncollected</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-expired" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span className="tx-11 tx-color-02 mg-b-0 mg-t-5 date-current">{currentDate}</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Collections</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {e.preventDefault(); reloadTable()}} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-waste" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-20p">Type</th>
                                        <th className="wd-20p">Area</th>
                                        <th className="wd-15p">Expiry Date</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Collection;
