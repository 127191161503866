import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {SEARCH_COLLECTION_URL, GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {collector, getPreSignedUrl, uploadPhoto} from "../../../api/app";
const Buffer = require('buffer/').Buffer;

const Collector = (props) => {

    document.title = "Collector";

    const token = localStorage.getItem('jwtToken');
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [logo_image, set_logo_image] = useState(null);

    useEffect(() => {
        let table = $('#collector').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Collector Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "collector",
                        fieldname: "name",
                        pageSize: data.start,
                        format: 'json',
                        populate: "country",
                        sort: {name: 1},
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return capitalize(data.name);
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.email;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(data.country.name);
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_collector"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link delete_collector"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.view_collector', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            props.history.push(`/collector/${extract_td.raw._id}`);
        });

        table.on('click', '.delete_collector', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.name)}?`);
            $('#delete-collector').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                collector("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Collector successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        return () => {
            table.destroy();
        }

    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setStateReady(true);

        let data = {
            name : $("#name").val(),
            address: $("#address").val(),
            logo_uri : await submitPhoto(),
            email : $("#email").val(),
            mobile_number : $("#mobile").val(),
            password : $("#password").val(),
            tax_id : $("#tax").val(),
            country: $("#country-select2").val()[0],
        }

        collector("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalCollector", "collector_form");
                reloadTable();
            }
        })

        return false;
    }

    const handleShowModal = () => {
        setEditForm(false);
        $('#type').prop("disabled", false).prop("required", true);
        $('#country-select2').prop("disabled", false).prop("required", true);
        $('#collector_form').parsley();
        initializeSearch();
        $("#modalCollector").modal({ backdrop: 'static', focus: false, show: true });
    };

    const initializeSearch = () => {
        let modalCollector = $('#modalCollector');
        $('#country-select2').select2({
            placeholder: 'Select country or type name',
            allowClear: true,
            maximumSelectionLength: 1,
            dropdownParent: modalCollector,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "country"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }
    const reloadTable = () => {
        $('#collector').DataTable().ajax.reload(null, false);
    };
    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#logo_image').parsley().isValid() !== false) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                set_logo_image(reader.result);
            };
            reader.onerror = () => {
                set_logo_image(null);
            };
        }
    };

    const submitPhoto = async() => {
        return getPreSignedUrl().then(async (response) => {
            if (response.error) {
                return false;
            }

            const config = {
                headers: {
                    'Content-Type': 'image/jpeg; charset=utf-8',
                    'x-amz-acl': 'public-read'
                }
            };

            const buffer = new Buffer.from(logo_image.replace(/^data:image\/\w+;base64,/, ""), 'base64');

            return uploadPhoto(response.payload.url, buffer, config).then((result) => {
                if (!result.error) {
                    return result.payload.url.replace(/\?.*$/g,"");
                }

                return null;
            });
        })
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Garbage Collectors</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Garbage Collectors</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-truck"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Garbage Collectors</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {e.preventDefault(); $('#td-client').DataTable().ajax.reload()}} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="collector" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Email</th>
                                        <th className="wd-10p">Mobile Number</th>
                                        <th className="wd-10p">Country</th>
                                        <th className="wd-10p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCollector" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalCollector", "collector_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Garbage Collector" : "New Garbage Collector"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} garbage collector information.</p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="collector_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                    <div id="countryWrapper" className="form-group parsley-input">
                                        <div className="d-flex justify-content-between">
                                            <label>Country<span className="tx-danger">*</span></label>
                                        </div>
                                        <select className="form-control" id="country-select2" data-width="100%" multiple="multiple"
                                                data-parsley-class-handler="#countryWrapper"
                                                data-parsley-errors-container="#countryWrapper"
                                                autoComplete="off"
                                                required>
                                        </select>
                                    </div>

                                    <div id="nameWrapper" className="form-group parsley-input">
                                        <label>Company Name<span className="tx-danger">*</span></label>
                                        <input id="name" type="text" className="form-control"
                                               placeholder="Enter company name"
                                               autoComplete="none"
                                               data-parsley-class-handler="#nameWrapper" required/>
                                    </div>

                                    <div id="logoWrapper" className="form-group parsley-input">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Company Logo</label>
                                        <input id="logo_image" type="file" className="form-control"
                                               onChange={handleFileUpload.bind()}
                                               data-parsley-filemaxmegabytes="1"
                                               data-parsley-trigger="change"
                                               data-parsley-filemimetypes="image/jpeg, image/jpg, image/png"
                                               accept="image/jpeg, image/jpg, image/png"
                                               data-parsley-class-handler="#logoWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="emailWrapper" className="form-group parsley-input">
                                        <label>Email<span className="tx-danger">*</span></label>
                                        <input id="email" type="email" className="form-control"
                                               placeholder="Enter email address"
                                               autoComplete="none"
                                               data-parsley-class-handler="#emailWrapper" required/>
                                    </div>

                                    <div id="mobileWrapper" className="form-group parsley-input">
                                        <label>Mobile Number<span className="tx-danger">*</span></label>
                                        <input id="mobile" type="number" className="form-control"
                                               placeholder="Enter mobile number"
                                               autoComplete="none"
                                               data-parsley-class-handler="#mobileWrapper" required/>
                                    </div>

                                    <div id="addressWrapper" className="form-group parsley-input">
                                        <label>Physical Address<span className="tx-danger">*</span></label>
                                        <input id="address" type="text" className="form-control"
                                               placeholder="Enter physical address"
                                               autoComplete="none"
                                               data-parsley-class-handler="#addressWrapper" required/>
                                    </div>

                                    <div id="taxWrapper" className="form-group parsley-input">
                                        <label>Tax ID</label>
                                        <input id="tax" type="text" className="form-control"
                                               placeholder="Enter tax ID or TPIN #"
                                               autoComplete="none"
                                               data-parsley-class-handler="#taxWrapper"/>
                                    </div>

                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        <label>Create Password<span className="tx-danger">*</span></label>
                                        <input id="password" type="text" className="form-control"
                                               placeholder="Enter new login password"
                                               autoComplete="none"
                                               data-parsley-class-handler="#passwordWrapper" required/>
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Collector</button> : <button className="btn btn-success btn-block">Create Collector</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content tx-14">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="mg-b-0 data-message">Empty</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="delete-collector" className="btn btn-success btn-block">Yes, Delete</button>
                                <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Collector;
