import React, {useEffect} from 'react';
const $ = window.$;

const VMap = () => {

    useEffect(() => {
        $('#vmap').vectorMap({
            map: 'zm',
            showTooltip: true,
            backgroundColor: '#fff',
            color: '#8da3ba',
            // colors: {
            //     ce: '#69b2f8',
            //     ca: '#69b2f8',
            //     tx: '#69b2f8',
            //     wy: '#69b2f8',
            //     ny: '#69b2f8'
            // },
            selectedColor: '#48729b',
            enableZoom: false,
            borderWidth: 1,
            borderColor: '#fff',
            hoverOpacity: .75
        });
    }, []);

    return (
        <div className="pd-y-25 pd-x-20">
            <div id="vmap" className="ht-200"></div>
        </div>
    );
};

export default VMap;
